/* const data = [
  {
    name: "Ms. Archana Soni",
    desc: "Chairperson",
  },
  {
    name: "Mr. Dhiraj Singh Rawat",
    desc: "Vice Chairman (Tr. Rep)",
  },
  {
    name: "Ms. Jyoti K. Nambiar",
    desc: "Vice Chairman (Parent Rep.)",
  },
  {
    name: "Mr. Arvind Bhardwaj",
    desc: "Secretary (Parent Rep)",
  },
  {
    name: "Ms. Swati Majumdar",
    desc: "Joint Secretary (Tr. Rep)",
  },
  {
    name: "Mr. Nair Radha Krishnan R",
    desc: "Joint Secretary (Parent Rep)",
  },
  {
    name: "Ms. Guranjan Kaur Bhatia",
    desc: "Hony. Treasurer",
  },
  {
    name: "Ms. Reeni Gulati",
    desc: "Teacher Rep.",
  },
  {
    name: "Ms. Bandana Singh",
    desc: "Teacher Rep.",
  },
  {
    name: "Ms. Alka Rohil",
    desc: "Teacher Rep.",
  },
  {
    name: "Ms. Neha Saini",
    desc: "Teacher Rep.",
  },
  {
    name: "Dr. Prashant Gupta",
    desc: "Parent Rep.",
  },
  {
    name: "Mr. Satish Mishra",
    desc: "Parent Rep.",
  },
  {
    name: "Ms. Ritu Antil",
    desc: "Parent Rep.",
  },
  {
    name: "Dr. S Pramila",
    desc: "Parent Rep.",
  },
  {
    name: "Mr. Puneet Tiwari",
    desc: "Parent Rep.",
  },
]; */

const data = [
  { name: "Ms. Archana Soni", desc: "Chairperson" },
  { name: "Mr. Dhiraj Singh Rawat", desc: "Vice Chairperson (Tr. Rep)" },
  { name: "Mr. Arvind Bhardwaj", desc: "Vice Chairperson (Parent Rep)" },
  { name: "Ms. Swati Majumdar", desc: "Joint Secretary (Tr. Rep)" },
  { name: "Dr. Prashant Gupta", desc: "Jr. Secretary (Parent Rep.)" },
  { name: "Ms. Guranjan Kaur Bhatia", desc: "Hony. Treasurer" },
  { name: "Ms. Nadisha Goswami", desc: "Teacher Rep." },
  { name: "Ms. Reeni Gulati", desc: "Teacher Rep." },
  { name: "Ms. Diksha Gandhi", desc: "Teacher Rep." },
  { name: "Ms. Archana Gaur", desc: "Teacher Rep" },
  { name: "Ms. Alka Rohil", desc: "Teacher Rep." },
  { name: "Ms. Neha Saini", desc: "Teacher Rep." },
  { name: "Mr. Nair Radha Krishnan R", desc: "Parent Rep." },
  { name: "Mr. Puneet Tiwari", desc: "Parent Rep." },
  { name: "Ms. Deepika Bhargava", desc: "Parent Rep." },
  { name: "Ms. Manisha Gulia", desc: "Parent Rep." },
  { name: "Ms. Maya Pandey", desc: "Parent Rep." },
  { name: "Mr. Amit Kumar Bhayani", desc: "Parent Rep." },
];

export default data;
